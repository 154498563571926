

jQuery(document).ready(function($) {

  $(document).trigger( "enhance.tablesaw" );
  

  $('.touch_menu').click(function(){
    $("#Page").toggleClass('nav-open');
    if($("#Page").hasClass('nav-open')){
      $('#mobileBtn').attr('src', '/Themes/SangCoTax/assets/dist/images/mobile-menu-2.png');
    } else {
      $('#mobileBtn').attr('src', '/Themes/SangCoTax/assets/dist/images/mobile-menu.png');
    }
  });

  // Drop selects
  if (!$('body').hasClass('dnnEditState')) {
    $('body').click(function() {
      $('ul#drop-select').removeClass('open');
      $('ul#drop-select > li:not(:first-child)').hide();
    });
    $('ul').removeClass('no-js');
    $(function(){
      $('ul#drop-select').click(function (e) {
        var $this = $(this);
        if ($this.hasClass('open')) {
          $this.removeClass('open');
          $('> li:not(:first-child)', $this).hide();
        } else {
          var $others = $('ul#drop-select').not($this);
          $others.removeClass('open');
          $('> li:not(:first-child)', $others).hide();

          $this.addClass('open');
          $('> li:not(:first-child)', $this).show();
        }
        e.stopPropagation();
      });
    });
  }

  // Expand Secondary menu level
  $('.top-menu li').click(function() {
    $(this).toggleClass('open');
  });

  // Place any jQuery/helper plugins in here.
  $(window).load(function() {
    // $('.wrap .ul1 li').hoverIntent(function () {
    //   $('.div2',this).slideToggle(200);
    // });
    $('img[longdesc]').each(function() {
      var title = $(this).attr('title');
      var longdesc = $(this).attr('longdesc');
      var alt = $(this).attr('alt');
      var imgsize =  $(this).css('width');
      $(this).wrap('<div class="caption"></div>').after('<em>' + longdesc + '</em>');
    });
      //For Mega Menu
      $('.ul2 .li2:nth-child(4n+4)').after('<li class="li2 clear"></li>');
      // $('.li1.I.Want.To .div2 .ul2').easyListSplitter({
      //    colNumber: 5,
      //    direction: 'horizontal'
      // });
      // $('.li1.E-Government .div2 .ul2').easyListSplitter({
      //    colNumber: 4,
      //    direction: 'vertical'
      // });
      // $('.li1.Courts .div2 .ul2').easyListSplitter({
      //    colNumber: 4,
      //    direction: 'vertical'
      // });
      // $('.li1.Property.Info .div2 .ul2').easyListSplitter({
      //    colNumber: 4,
      //    direction: 'vertical'
      // });
      // $('.li1.County.Board .div2 .ul2').easyListSplitter({
      //    colNumber: 4,
      //    direction: 'vertical'
      // });
      // $('.li1.Elected.Offices .div2 .ul2').easyListSplitter({
      //    colNumber: 4,
      //    direction: 'vertical'
      // });
      // $('.li1.Departments .div2 .ul2').easyListSplitter({
      //    colNumber: 4,
      //    direction: 'vertical'
      // });
      // $('#govt_trans .ul1').easyListSplitter({
      //    colNumber: 3,
      //    direction: 'vertical'
      // });
      /* Add span tag for menu toggle */
      $('ul.panel.selected .li2.haschildren').append("<span></span>");
      /* Expand Menu for section */
      $('ul.panel.selected .li2.haschildren.selected span').addClass('open').prev().show();
      /* Toggle menu and close any expanded menus */
      $('ul.panel.selected .li2.haschildren span').click(function () {
       var btn = $(this);
       var menu = $(this).prev();
       if (btn.hasClass('open')) {
        btn.removeClass('open');
        menu.slideUp('fast');
      } else {
       $('ul.panel.selected .li2.haschildren span').removeClass('open').prev().hide();
       btn.addClass('open');
       menu.slideDown('fast');
     }
   });
    });

});

function autoTab(theControl, maxlen, nextcontrolid) {
  if (theControl.value.length >= maxlen) {
    document.getElementById(nextcontrolid).focus();

  }
}

window.name = "home";

var win = null;

function closeDep() {
  if (win && win.open && !win.closed) win.close();
}

function NewWindow(mypage, myname, w, h, scroll) {
  var winl = 300;
  var wint = 130;
  winprops = 'height=' + h + ',width=' + w + ',top=' + wint + ',left=' + winl + ',scrollbars=' + scroll + ',resizable';
  win = window.open(mypage, myname, winprops);
  if (parseInt(navigator.appVersion) >= 4) { win.window.focus(); }
}